<template>
  <header>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="height-align-center logo-center">
            <img src="/img/icons/logo-diablo.svg" alt="logo" class="logo"/>
          </div>
        </div>

        <div class="col-12 d-md-block d-none">
          <div class="height-align-center center">
            <div class="menu-items">

              <button class="menu-item" @click="scrollTo('creator-category')">
                <img src="/img/icons/creator.svg" alt="logo" class="menu-img"/>
                <p class="menu-desc">Twórca roku</p>
              </button>
              <button class="menu-item" @click="scrollTo('chair-category')">
                <img src="/img/icons/chair.svg" alt="logo" class="menu-img"/>
                <p class="menu-desc">Fotel roku</p>
              </button>
              <button class="menu-item" @click="scrollTo('advert-category')">
                <img src="/img/icons/advert2.svg" alt="logo" class="menu-img"/>
                <p class="menu-desc">Reklama roku</p>
              </button>
              <button class="menu-item" @click="scrollTo('gadget-category')">
                <img src="/img/icons/gadget.svg" alt="logo" class="menu-img"/>
                <p class="menu-desc">Gadżet roku</p>
              </button>

            </div>
          </div>
        </div>

        <div class="offset-lg-3"></div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
  methods: {
    scrollTo(element) {
      const el = document.getElementById(element);
      el.scrollIntoView({behavior: "smooth"});
    }
  }
}
</script>