<template>
  <div class="vote-section" id="vote-category">

    <div class="container">
      <div class="row" v-if="!already_vote">

        <div class="col-lg-12">
          <h2>Głosuj<br> i wygrywaj</h2>
        </div>

        <div class="col-lg-6 col-md-12">
          <div class="vote-status-icons">

            <div class="vote-status-icon-block">
              <img v-if="creator_id !== 0" src="/img/icons/creator-active.svg" alt="logo" class="vote-icon"/>
              <img v-else src="/img/icons/creator.svg" alt="logo" class="vote-icon"/>

              <img v-if="creator_id !== 0" src="/img/icons/vote-check.svg" alt="logo" class="check-icon"/>
              <img v-else src="/img/icons/vote-close.svg" alt="logo" class="check-icon"/>
            </div>

            <div class="vote-status-icon-block">
              <img v-if="chair_id !== 0" src="/img/icons/chair-active.svg" alt="logo" class="vote-icon"/>
              <img v-else src="/img/icons/chair.svg" alt="logo" class="vote-icon"/>

              <img v-if="chair_id !== 0" src="/img/icons/vote-check.svg" alt="logo" class="check-icon"/>
              <img v-else src="/img/icons/vote-close.svg" alt="logo" class="check-icon"/>
            </div>

            <div class="vote-status-icon-block">
              <img v-if="advert_id !== 0" src="/img/icons/advert-active.svg" alt="logo" class="vote-icon"/>
              <img v-else src="/img/icons/advert2.svg" alt="logo" class="vote-icon"/>

              <img v-if="advert_id !== 0" src="/img/icons/vote-check.svg" alt="logo" class="check-icon"/>
              <img v-else src="/img/icons/vote-close.svg" alt="logo" class="check-icon"/>
            </div>

            <div class="vote-status-icon-block">
              <img v-if="gadget_id !== 0" src="/img/icons/gadget-active.svg" alt="logo" class="vote-icon"/>
              <img v-else src="/img/icons/gadget.svg" alt="logo" class="vote-icon"/>

              <img v-if="gadget_id !== 0" src="/img/icons/vote-check.svg" alt="logo" class="check-icon"/>
              <img v-else src="/img/icons/vote-close.svg" alt="logo" class="check-icon"/>
            </div>

          </div>

          <p class="vote-desc">Uzasadnij dlaczego to właśnie TEN fotel powinien wygrać nasz plebiscyt. Naprawdę warto, bo w zamian możesz zdobyć jeden z naszych fenomenalnych foteli z X-SERIES!</p>
        </div>

        <div class="col-lg-6 col-md-12">

          <div class="vote-form">
            <form @submit.prevent="vote()">
              <label for="email">Podaj adres email</label>
              <input type="email" class="input" placeholder="Podaj adres e-mail" id="email" v-model="email" required/>

              <label for="message" class="mt-3">Jeśli chcesz wziąć udział w konkursie i wygrać fotel z serii X to odpowiedz na pytanie „Dlaczego wybrałeś fotel, na który zagłosowałeś?” (opcjonalnie)</label>
              <textarea class="input" placeholder="Wpisz treść..." id="message" v-model="message"/>

              <p class="error-info mb-3" v-if="!one_category">
                <img src="/img/icons/vote-close.svg" alt="logo" class="check-icon"/>
                Żeby wziąć udział w konkursie musisz oddać głos przynajmniej w jednej kategorii.
              </p>

              <button type="submit" class="main-btn submit-btn" :disabled="!one_category">Głosuję</button>

              <ul class="errors">
                <li v-for="err in dynamic_errors" :key="err">{{ err[0] }}</li>
              </ul>

              <div class="checkbox">
                <input id="marketing_data" type="checkbox" v-model="agree" required/>
                <label for="marketing_data">Oświadczam, że zapoznałem(am) się z Regulaminem i akceptuję jego postanowienia. * <span>Administratorem danych osobowych w powyższym formularzu jest Domator24 spółka z ograniczoną odpowiedzialnością. Podanie danych jest dobrowolne, jednak jeśli ich nie podasz, nie będziesz mógł(a) wypełnić formularza. Szczegółowe informacje na temat przetwarzania twoich danych osobowych przez administratora i twoich uprawnieniach znajdziesz tutaj.</span></label>
              </div>

              <div class="checkbox">
                <input id="agree_data" type="checkbox" v-model="newsletter"/>
                <label for="agree_data">Jeśli chcesz otrzymywać od nas informacje o produktach, nowościach i promocjach, zapisz się do newslettera Diablo Chairs! Obiecujemy nie spamować</label>
              </div>
            </form>
          </div>

        </div>

      </div>

      <div v-if="already_vote" class="row">
        <div class="col-lg-12">
          <div class="vote-success-form">
            <img src="/img/icons/success-logo.svg" alt="logo" class="logo"/>
            <p class="vote-desc">Twój głos został poprawnie oddany.</p>
            <a href="https://diablochairs.com" class="main-btn success-btn">DiabloChairs</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Vote',
  data() {
    return {
      // Form
      email: '',
      message:'',
      agree: '',
      newsletter: '',

      dynamic_error: '',
      dynamic_errors: []
    }
  },
  methods: {
    vote() {
      const form = new FormData()

      form.set('email', this.email)
      form.set('chair_id', this.chair_id)
      form.set('gadget_id', this.gadget_id)
      form.set('advert_id', this.advert_id)
      form.set('creator_id', this.creator_id)
      form.set('message', this.message)
      form.set('agree', this.agree)
      form.set('newsletter', this.newsletter)

      this.$http.post('https://api2.verspro.co.uk/api/diablo-vote',
          form,
          {
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'multipart/form-data',
            }
          })
          .then(({ data }) => {
            if (data.error) {
              this.dynamic_error = '';

              this.dynamic_errors = data.errors;
            } else if (data.success) {
              this.dynamic_error = '';
              this.dynamic_errors = '';

              window.localStorage.clear();
              this.already_vote = true;
            }
          });
    }
  },
  computed: {
    advert_id: { get() { return this.$store.state.advert_id; } },
    gadget_id: { get() { return this.$store.state.gadget_id; } },
    chair_id: { get() { return this.$store.state.chair_id; } },
    creator_id: { get() { return this.$store.state.creator_id; } },
    one_category: { get() { return this.$store.state.one_category; } },
    already_vote: {
      get() { return this.$store.state.already_vote; },
      set(value) { this.$store.commit('setAlreadyVote', {already_vote: value}) }
    }
  }
}
</script>