<template>
  <div class="category-section" id="advert-category">

    <div class="category-section-top mt-4 mb-4">
      <img src="/img/icons/category-advert.svg" alt="logo" class="category-img"/>
      <div class="category-section-top-content">
        <p class="subhead"><span>III</span> Kategoria</p>
        <h2><span>Reklama</span> roku</h2>
      </div>
    </div>

    <div class="category-section-content">
      <div class="row">

        <div class="col-lg-2 col-md-3 col-sm-4 col-6" v-for="advert in adverts" :key="advert">
          <div :class="{ active: advert.id === advert_id, 'category-item-block': true }" @click="setAdvert(advert.id)">

            <div class="top-category-block">
              <img :src="'/img/adverts/' + advert.image" alt="logo" class="category-img"/>

              <div class="name-align-center">
                <p class="name">
                  <img v-if="advert.type === 'tiktok'" src="/img/icons/tiktok.svg" alt="logo" class="social-img mr-2"/>
                  <img v-else src="/img/icons/youtube.svg" alt="logo" class="social-img mr-2"/>

                  {{ advert.name }}
                </p>
              </div>
            </div>

            <button class="main-btn bottom-category-block">
              <span class="round"></span>
              <span class="title">Wybieram</span>
            </button>
          </div>

          <a :href="advert.advert_url_first" target="_blank" class="link"><img src="/img/icons/player.svg" alt="logo" class="player-img mr-2"/> Zobacz</a>
          <a :href="advert.advert_url_second" v-if="advert.advert_url_second !== ''" target="_blank" class="link"><img src="/img/icons/player.svg" alt="logo" class="player-img mr-2"/> Zobacz</a>
        </div>
      </div>
    </div>

    <div class="category-button-content">
      <button class="category-button" @click="scrollTo('gadget-category')">
        <span>Następna </span> kategoria
        <img src="/img/icons/top.svg" alt="top" class="top-img"/>
      </button>
    </div>

  </div>
</template>

<script>
import {ADVERTS} from "@/utils/data";

export default {
  name: 'Advert',
  data() {
    return {
      adverts: ADVERTS
    }
  },
  methods: {
    setAdvert(id) {
      this.advert_id = id;
      this.one_category = true;
    },
    scrollTo(element) {
      const el = document.getElementById(element);
      el.scrollIntoView({behavior: "smooth"});
    }
  },
  computed: {
    advert_id: {
      get() {
        return this.$store.state.advert_id;
      },
      set(value) {
        this.$store.commit('setAdvertId', {advert_id: value})
      }
    },
    one_category: {
      get() {
        return this.$store.state.one_category;
      },
      set(value) {
        this.$store.commit('setOneCategory', {one_category: value})
      }
    },
  }
}
</script>