<template>
  <div class="home">
    <transition name="fade" mode="out-in">
      <Cookie></Cookie>
    </transition>

    <Header></Header>

    <div class="container">
      <div class="row">
        <div class="col-lg-12">

          <div class="home-main-section">
            <img src="/img/icons/plebiscyt-full-logo.svg" alt="logo" class="logo"/>
            <p class="desc">Weź udział w corocznym Plebiscycie Diablo Chairs! Oddaj głos na swoich faworytów w czterech kategoriach i WALCZ O MEGA NAGRODĘ! Dziękujemy za udział i życzymy powodzenia.</p>
          </div>

          <Creator></Creator>
          <Chair></Chair>
          <Advert></Advert>
          <Gadget></Gadget>

          <Vote></Vote>

        </div>
      </div>
    </div>

    <Footer></Footer>

  </div>
</template>

<script>
import Cookie from "@/components/partials/Cookie.vue";
import Creator from "@/components/categories/Creator";
import Chair from "@/components/categories/Chair";
import Advert from "@/components/categories/Advert";
import Gadget from "@/components/categories/Gadget";
import Header from "@/components/partials/Header";
import Footer from "@/components/partials/Footer";
import Vote from "@/components/categories/Vote";

export default {
  name: 'Home',
  components: {
    Cookie, Creator, Chair, Advert, Gadget, Header, Footer, Vote
  }
}
</script>