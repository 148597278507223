import { createApp } from 'vue'
import App from './App.vue';
import router from './router';
import VueAxios from "vue-axios";
import axios from "axios";
import store from "./store/store";
import { VueCookieNext } from 'vue-cookie-next'

const app = createApp(App);

app.config.productionMode = true;

app.use(VueAxios, axios);
app.use(VueCookieNext);
app.use(store);

app.use(router).mount('#app',);