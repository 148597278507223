export const CHAIRS = {
    "1": {
        "id": 1,
        "name": "X-Player",
        "image": "Fotel_xPlayer.jpg",
        "url": "https://diablochairs.com/3715-fotel-dla-graczy-diablo-x-player-new-bialo-czarny"
    },
    "2": {
        "id": 2,
        "name": "X-One",
        "image": "Fotel_xOne.jpg",
        "url": "https://diablochairs.com/3706-fotel-gamingowy-diablo-x-one-2-0-normal-size-czarno-czerwony"
    },
    "3": {
        "id": 3,
        "name": "X-Ray",
        "image": "Fotel_xRay.jpg",
        "url": "https://diablochairs.com/3640-fotel-gamingowy-diablo-x-ray-bialo-czarny"
    },
    "4": {
        "id": 4,
        "name": "X-Gamer",
        "image": "Fotel_xGamer.jpg",
        "url": "https://diablochairs.com/3798-fotel-gamingowy-diablo-x-gamer-2-0-normal-size-marshmallow-pink"
    },
    "5": {
        "id": 5,
        "name": "V-Commander",
        "image": "Fotel_vCommander.jpg",
        "url": "https://diablochairs.com/3743-fotel-ergonomiczny-v-commander-czarno-czarny"
    },
    "6": {
        "id": 6,
        "name": "X-St4rter",
        "image": "Fotel_xSt4rter.jpg",
        "url": "https://diablochairs.com/3832-zestaw-fotel-gamingowy-diablo-x-st4rter-poduszka-podnozek"
    }
};

export const GADGETS = {
    "1": {
        "id": 1,
        "name": "Podkładka Electric Yellow",
        "image": "Items_mousepad_Electric.jpg",
        "url": "https://diablochairs.com/3816-podkladka-gamingowa-diablo-chairs-electric-yellow"
    },
    "2": {
        "id": 2,
        "name": "Podkładka Craft Edition",
        "image": "Items_mousepad_Craft.jpg",
        "url": "https://diablochairs.com/3817-podkladka-gamingowa-diablo-chairs-craft"
    },
    "3": {
        "id": 3,
        "name": "Podkładka Aqua Blue",
        "image": "Items_mousepad_Aqua.jpg",
        "url": "https://diablochairs.com/3818-podkladka-gamingowa-diablo-chairs-aqua-blue"
    },
    "4": {
        "id": 4,
        "name": "Podkładka Candy Rose",
        "image": "Items_mousepad_Candy.jpg",
        "url": "https://diablochairs.com/3819-podkladka-gamingowa-diablo-chairs-candy-rose"
    },
    "5": {
        "id": 5,
        "name": "Plecak",
        "image": "Items_plecak.jpg",
        "url": "https://diablochairs.com/3838-plecak-diablo-chairs-czarny"
    },
    "6": {
        "id": 6,
        "name": "Nerka",
        "image": "Items_Sling.jpg",
        "url": "https://diablochairs.com/3839-plecak-sling-bag-diablo-chairs-czarny"
    }
};

export const ADVERTS = {
    "1": {
        "id": 1,
        "name": "AGBE",
        "image": "agbae2.jpg",
        "type": "tiktok",
        "advert_url_first": "https://www.tiktok.com/@agbe_official/video/7098759864628841733?is_copy_url=1&is_from_webapp=v1&item_id=7098759864628841733",
        "advert_url_second": ""
    },
    "2": {
        "id": 2,
        "name": "Sitarson",
        "image": "reklama_Sitarson3.jpg",
        "type": "tiktok",
        "advert_url_first": "https://www.tiktok.com/@sitars0n/video/7122852006406016261?is_copy_url=1&is_from_webapp=v1&item_id=7122852006406016261&lang=pl-PL",
        "advert_url_second": ""
    },
    "3": {
        "id": 3,
        "name": "opposango",
        "image": "reklama_Oposango2.jpg",
        "type": "tiktok",
        "advert_url_first": "https://www.tiktok.com/@oposango/video/7124290398821960966?is_copy_url=1&is_from_webapp=v1&item_id=7124290398821960966&lang=pl-PL",
        "advert_url_second": ""
    },
    "4": {
        "id": 4,
        "name": "Milusia Klaudusia",
        "image": "milusia_klaudusia2.jpg",
        "type": "youtube",
        "advert_url_first": "https://www.youtube.com/watch?v=NqOP6J-GGW8",
        "advert_url_second": ""
    },
    "5": {
        "id": 5,
        "name": "Instatruelovee",
        "image": "reklama_Instatruelovee3.jpg",
        "type": "tiktok",
        "advert_url_first": "https://www.tiktok.com/@instatruelovee/video/7156301750629158150?is_copy_url=1&is_from_webapp=v1&item_id=7156301750629158150",
        "advert_url_second": ""
    }
};

export const CREATORS = {
    "1": {
        "id": 1,
        "name": "Izer",
        "image": "Izer.png"
    },
    "2": {
        "id": 2,
        "name": "Szhakal",
        "image": "shakal.jpg"
    },
    "3": {
        "id": 3,
        "name": "iSuperJerry",
        "image": "tworca_iSuperJerry.jpg"
    },
    "4": {
        "id": 4,
        "name": "Ciocia od klocków",
        "image": "Ciocia_od_klocków.jpg"
    },
    "5": {
        "id": 5,
        "name": "opposango",
        "image": "tworca_Oposango.jpg"
    },
    "6": {
        "id": 6,
        "name": "videofunlab",
        "image": "tworca_svideofunlab.jpg"
    },
    "7": {
        "id": 7,
        "name": "Torii",
        "image": "Torii.jpg"
    },
    "8": {
        "id": 8,
        "name": "andrew_gazda",
        "image": "andrew.jpg"
    },
    "9": {
        "id": 9,
        "name": "Mikinik23",
        "image": "tworca_Miki niki23.jpg"
    },
    "10": {
        "id": 10,
        "name": "Mafia Solec",
        "image": "solec.jpg"
    },
    "11": {
        "id": 11,
        "name": "Tanas",
        "image": "tanas.png"
    },
    "12": {
        "id": 12,
        "name": "Milusia Klaudusia",
        "image": "milusia_klaudusia.jpg"
    },
    "13": {
        "id": 13,
        "name": "Enchant.pl",
        "image": "Serwer_Enchant.pl.png"
    },
    "14": {
        "id": 14,
        "name": "szymeQ",
        "image": "szymeQ.jpg"
    },
    "15": {
        "id": 15,
        "name": "shirkles",
        "image": "shirkles.jpg"
    },
    "16": {
        "id": 16,
        "name": "Pedro",
        "image": "pedro.jpg"
    },
    "17": {
        "id": 17,
        "name": "AGBE",
        "image": "agbae2.jpg"
    },
    "18": {
        "id": 18,
        "name": "Sitarson",
        "image": "reklama_Sitarson3.jpg"
    },
    "19": {
        "id": 19,
        "name": "instatruelovee",
        "image": "reklama_Instatruelovee3.jpg"
    }
};