<template>
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="regulation-box">
          <router-link :to="{ name: 'Home' }">
            <img src="/img/icons/plebiscyt-full-logo.svg" alt="logo" class="logo"/>
          </router-link>

          <h3>§1</h3>

          <ol>
            <li>Plebiscyt organizowany jest przez Pawła Nowaka prowadzącego działalność gospodarczą pod firmą Domator24.com Paweł Nowak, Numer NIP 9730551869, Numer REGON 978094558,  ul. Dekoracyjna nr 8, 65-155 Zielona Góra zwany dalej Organizatorem.</li>
            <li>Plebiscyt organizowany jest w czterech niezależnych kategoriach:
              <ol type="a">
                <li>Fotel Roku,</li>
                <li>Gadżet Roku,</li>
                <li>Twórca Roku,</li>
                <li>Najlepsza Reklama.</li>
              </ol>
            </li>

            <li>Plebiscyt organizowany będzie za pośrednictwem strony internetowej https://plebiscyt.diablochairs.com/ zwana dalej <b>Stroną</b>.</li>

            <li>Plebiscyt trwa w okresie od 15.11.2021 do 31.12.2021 roku, co oznacza, że w tym okresie istnieje możliwość oddawania głosów w każdej z kategorii. Wyniki zostaną opublikowane na Stronie w dniu 14.01.2022 roku.</li>

            <li>Dla laureatów w kategorii Twórca Roku Organizator przewiduje nagrody w postaci statuetek okolicznościowych (dla 3 pierwszych miejsc) a dla laureata, który otrzymał największą liczbę głosów dodatkowo personalizowany fotel i voucher o wartości 1000 zł. W kategorii Najlepsza Reklama Organizator przewiduje nagrody dla laureata, który otrzymał największą liczbę głosów w postaci statuetki i vouchera o wartości 1000 zł.  W pozostałych kategoriach nagrody nie będą przyznawane. Głosujący także nie otrzymują nagród w związku z oddaniem głosu. Jednakże w ramach Plebiscytu możliwy jest udział Głosujących w konkursie, którego regulamin stanowi Załącznik nr 1 do niniejszego regulaminu. Udział w konkursie nie jest obowiązkowy i nie jest warunkiem oddania skutecznego głosu.  </li>

            <li>Nadzór nad prawidłowością przebiegu plebiscytu sprawować będzie trzyosobowa Kapituła w osobie Organizatora i dwóch pracowników działu marketingu.</li>
          </ol>

          <h3>§2</h3>

          <ol>
            <li>Kandydatury w kategoriach wskazanych w §1 ust 2 lit a) i b) wybiera Organizator spośród dystrybuowanych przez siebie towarów.</li>

            <li>Zgłoszenie kandydatury w kategorii §1 ust 2 lit c) i d) odbywa się w ten sposób, że Organizator wysyła zaproszenia do  osób, z którymi współpracował w okresie od 1.01.2021 w zakresie promocji swoich produktów w portalu społecznościowym YouTube (zwani dalej <b>Twórcami</b>). </li>

            <li>Przystąpienie  Twórcy do plebiscytu odbywa się poprzez:

              <ol type="a">

                <li>wyrażenie zgody na udział w kategorii Twórca Roku, Najlepsza Reklama lub obu naraz (za zgodę uważa się także uprzednio wyrażone w tym zakresie zgody, o ile nie zostały wycofane),</li>

                <li>wyrażenie zgody na przetwarzanie danych osobowych.</li>
              </ol>
            </li>

            <li>Zgłoszenia Twórców przyjmowane są do dnia 31.10.2021 roku na adres plebiscyt@diablochairs.com</li>

            <li>Przystąpienie do plebiscytu jest nieodpłatne.  </li>
          </ol>

          <h3>§3</h3>

          <ol>
            <li>Wybór laureatów w każdej z kategorii odbywać się będzie poprzez oddanie głosu na Stronie z wykorzystaniem  udostępnionych w tym celu mechanizmów.</li>

            <li>Wyboru może dokonać każda osoba korzystająca z Internetu (zwana dalej Głosującym) po spełnieniu następujących przesłanek:
              <ol type="a">
                <li>podanie adresu email,</li>
                <li>wyrażenie zgody na przetwarzanie danych osobowych w zakresie niezbędnym do oddania głosu.</li>
              </ol>
            </li>

            <li>W przypadku osób poniżej 16 roku życia zgodę na udostępnianie w/w danych winni wyrazić jego rodzice lub opiekunowie prawni. </li>

            <li>Głosujący może oddać tylko jeden głos w każdej z kategorii. </li>

            <li>Organizator będzie weryfikował osoby głosujące po podanym adresie email oraz numerze IP. </li>

            <li>Organizatorowi przysługuje uprawnienie do nieuwzględnienia głosów które naruszają regulamin, a w szczególności co do których Organizator posiada uzasadnione podejrzenie pochodzenia od jednego Głosującego. </li>

            <li>W przypadku podejrzenia rażącego naruszenia regulamin (np. znacznej dysproporcji w oddanych głosach,  IP wskazujących na korzystanej z usługi VPN) Organizatorowi przysługuje uprawnienie do usunięcia kandydatury Twórcy po wcześniejszym umożliwieniu zajęcia przez niego stanowiska. </li>

          </ol>

          <h3>§4</h3>

          <ol>
            <li>Dla poprawnego korzystania ze Strony i oddania głosu urządzenie końcowe Głosującego powinno spełniać następujące wymogi:
              <ul>
                <li>przeglądarka internetowa obsługująca standardy HTML, CSS, JavaScript </li>
                <li>włączona obsługa JavaScript</li>
                <li>włączona obsługa plików Cookies</li>
              </ul></li>

            <li>Oddanie głosu ma charakter jednorazowej czynności. Po oddaniu głosu jego cofnięcie nie jest możliwe.</li>

            <li>Ewentualne reklamacje należy zgłaszać pod adres plebiscyt@diablochairs.com ewentualnie telefonicznie na numer +48570158100</li>

            <li>Reklamacje będą rozpatrywane w terminie nie dłuższym niż 14 dni. O wyniku postępowania reklamacyjnego Organizator poinformuje tym samym kanałem jakim dokonano zgłoszenia. </li>

            <li>Twórca ani Glosujący nie mogą wykorzystywać Strony do dostarczania treści o charakterze bezprawnym.</li>


          </ol>


          <h3>§5</h3>

          <ol>
            <li>Administratorem Danych osobowych przekazanych przez Głosującego i Twórcę jest Organizator.</li>


            <li>Administrator powołał Inspektora Ochrony Danych Osobowych, z którym można się skontaktować pod adresem: iodo@domator24.com.</li>

            <li>Organizator oświadcza, iż wszelkie dane osobowe, które uzyskuje podlegają ochronie zgodnie z przepisami dotyczącymi ochrony danych osobowych  i podlegają przetwarzaniu tylko i wyłącznie w takim zakresie w jakim zezwala na to ustawa o świadczeniu usług drogą elektroniczną, chyba że Państwo wyrazili zgodę na przetwarzanie danych w szerszym zakresie. Przetwarzanie danych osobowych odbywa się zgodnie z przepisami Rozporządzenia Parlamentu Europejskiego I Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (RODO)</li>

            <li>Dane przetwarzane są na podstawie odrębnie udzielonej zgody o jakiej mowa w art. 6 ust. 1 lit. a RODO , a w zakresie w jakich dane dotyczą nadzoru nad prawidłowością przebiegu plebiscytu podstawę stanowi  art. 6 ust 1 lit f) RODO.</li>

            <li>Podanie danych ma charakter dobrowolny, ale jest konieczne w celu oddania głosu lub zgłoszenia kandydatury Youtubera.</li>


            <li>Dane osobowe nie będą przekazywane do państwa trzeciego/organizacji międzynarodowej.</li>

            <li>Dane Głosujących przechowywane są przez okres 3 miesiące od dnia ogłoszenia wyników plebiscytu, a w przypadku w którym Organizator uzyska informacje, że dane mogą być wykorzystane jako dowód w postępowaniu na podstawie przepisów prawa przez okres niezbędny do prawomocnego zakończenia tego postępowania. W sytuacji gdyby Głosujący  wycofał zgodę na przetwarzanie danych przed oddaniem głosu jego dane zostaną usunięte w terminie 30 dni</li>

            <li>Twórcy/Głosującemu przysługuje prawo dostępu do danych, które ich dotyczą, prawo do ich sprostowania czy żądania usunięcia, a także ograniczenia przetwarzania. </li>

            <li>Twórcy /Głosujący ma prawo do wniesienia sprzeciwu co do przetwarzania danych w dowolnym momencie.</li>

            <li>Twórcy/Głosujący ma prawo żądania przeniesienia danych.</li>

            <li>Twórcy/Głosującemu przysługuje prawo do wycofania zgody na przetwarzanie danych osobowych w dowodnym momencie. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem, jednakże skutkować będzie brakiem możliwości uczestnictwa w plebiscycie. </li>

            <li>Twórcy/Głosujący ma prawo wniesienia skargi w zakresie przetwarzania do organu nadzorczego w postaci Prezesa Urzędu Ochrony Danych Osobowych, ul. Stawki 2, 00-193 Warszawa.</li>

            <li>Organizator nie wykorzystuje danych do zautomatyzowanego podejmowania decyzji, w tym do profilowania na potrzeby plebiscytu. Dane podane dobrowolnie w celach marketingowych będą wykorzystywane w celach  przygotowania dopasowanych pod kątem przekazanych danych  reklam i wiadomości.</li>

          </ol>

          <h3>§6</h3>

          <ol>
            <li>Organizator zastrzega możliwość zmiany regulaminu z ważnych powodów o czym poinformuje na Stronie jak i za pośrednictwem wiadomości poczty elektronicznej na adresy przekazane przez Twórców i Głosujących.</li>

            <li>W przypadku ewentualnych sporów mogących wyniknąć na tle stosowania mniejszego regulaminu Sądem właściwym dla ich rozpoznania będzie Sąd powszechny siedziby Organizatora. Zapis nie dotyczy Głosujących oraz tych Twórców, którzy spełniają definicję konsumenta w rozumieniu przepisów Kodeksy cywilnego. </li>

            <li>Regulamin udostępniony jest na Stronie do dnia 14.01.2022 r. </li>

          </ol>

          <p>Załącznik nr 1<br>Regulamin Konkursu „Fotel Roku 2021”</p>
          <h2>Regulamin konkursu „Fotel Roku 2021” </h2>

          <p>§1. Organizator Wydarzenia</p>

          <ol>

            <li>Organizatorem wydarzenia (dalej jako „Konkurs”) i fundatorem nagród jest Domator24.com - Paweł Nowak, ul. Dekoracyjna 8, 65-155 Zielona Góra, NIP 9730551869 , REGON 978094558 (dalej jako „Organizator” lub „Fundator”). Konkurs organizowany jest za pośrednictwem strony https://plebiscyt.diablochairs.com</li>

            <li>Konkurs nie jest grą losową, loterią fantową, zakładem wzajemnym, loterią promocyjną, grą, której wynik zależy od przypadku, ani żadną inną formą przewidzianą w ustawie z dnia 19 listopada 2009 r. o grach hazardowych.</li>

          </ol>


          <p>§2. Czas trwania Konkursu</p>

          <ol>

            <li>Konkurs trwa  <b>od 8.11.2021  do dnia 31.12.2021  (godz.: 23:59).</b> Oznacza to, że w Konkursie biorą udział tylko Ci Uczestnicy, którzy w tym okresie spełnią warunki uczestnictwa w Konkursie</li>

            <li>Konkurs nie jest grą losową, loterią fantową, zakładem wzajemnym, loterią promocyjną, grą, której wynik zależy od przypadku, ani żadną inną formą przewidzianą w ustawie z dnia 19 listopada 2009 r. o grach hazardowych.</li>

            <li>O laureatach Konkursu Organizator poinformuje w dniu <b>14.01.2021</b></li>

            <li>Informacja o laureatach zostanie podana  na stronie Organizatora pod adresem plebiscyt.diablochairs.com/</li>

          </ol>

          <p>§3. Komisja Konkursowa</p>

          <ol>

            <li>Celem zapewnienia prawidłowej organizacji i przebiegu Konkursu, a w szczególności w celu dokonania oceny prawidłowości zgłoszeń do Konkursu oraz wyłonienia laureatów Organizator powołał komisję konkursową (dalej jako „Komisja”). W skład Komisji wchodzą pracownicy Działu Marketingu Diablo Chairs. Komisja podejmuje decyzje większością głosów. </li>

          </ol>

          <p>§4. Warunki uczestnictwa w Konkursie</p>

          <ol>

            <li>W Konkursie mogą brać udział osoby ﬁzyczne, zamieszkałe na terytorium Rzeczypospolitej Polskiej posiadające pełną zdolność do czynności prawnych. Jeżeli udział w Konkursie bierze osoba niepełnoletnia lub osoba podlagająca ograniczeniom w zakresie czynności prawnych  powinna posiadać pisemną zgodę swojego przedstawiciela ustawowego na udział w Konkursie. W przypadku wygranej zgodę należy przekazać Organizatorowi. Wzór zgody stanowi załącznik do niniejszego regulaminu.</li>

            <li>Udział w Konkursie i związane z nim udostępnienie danych jest całkowicie dobrowolne. Osoba, która przystąpiła do Konkursu (dalej jako „Uczestnik”) jest związana warunkami Regulaminu.</li>

            <li>Przystąpienie do konkursu odbywa się poprzez zamieszczeniu w ramach „Plebiscytu  Diablo Chairs 2021” pracy słownej w dowolnie wybranej formie literackiej, nie dłuższej niż 500 znaków, której tematem będzie odpowiedź na pytanie <em>„Dlaczego oddałem swój głos na wybrany model fotela”.</em> </li>

            <li>Praca Konkursowa powinna spełniać następujące wymogi:
              <ul>
                <li>stanowić przejaw działalności twórczej Uczestnika,</li>
                <li>stworzona zostanie w języku polskim.</li>
              </ul>
            </li>

            <li>W Konkursie nie mogą brać udziału pracownicy, członkowie władz i przedstawiciele Organizatora, a także osoby współpracujące z Organizatorem na innej podstawie niż stosunek pracy oraz członkowie ich najbliższych rodzin, tj. wstępni, zstępni, rodzeństwo, małżonkowie, rodzice małżonków i osoby pozostające z nimi w stosunku przysposobienia.</li>

            <li>Poprzez wysłanie Pracy Konkursowej  Uczestnik wyraża zgodę na zapis Regulaminu oraz przetwarzanie danych osobowych na zasadach opisanych w regulaminie.</li>

          </ol>

          <p>§5. Zasady i przebieg konkursu</p>

          <ol>
            <li>Poprzez udział w Konkursie Uczestnik wyraża zgodę na prezentacje prac na stronie Organizatora www.diablochairs.com. Do oznaczenia Uczestnika zostanie użyte oznaczanie wskazane  przez Laureata. </li>
            <li>Organizator ma prawo odrzucenia Pracy Konkursowej i/lub pozbawienia prawa uczestnictwa w Konkursie Uczestnika w przypadku:
              <ul>
                <li>naruszania niniejszego regulaminu,</li>
                <li>naruszani przepisów powszechnie obowiązującego prawa, w szczególności poprzez wykorzystywanie konkursu do popełnienia przestępstwa lub wykroczenia,</li>
                <li>naruszania dóbr osobistych osób trzecich, np. poprzez ich szykanowanie, przedstawiane w negatywnym świetle itp,</li>
                <li>naruszenia praw autorskich lub praw pokrewnych osób trzecich,</li>
                <li>stanowiące przejaw jakiejkolwiek dyskryminacji.</li>
              </ul></li>

            <li>Decyzje o jakiej mowa w ust 2 podejmuje Komisja, której decyzja jest ostateczna.</li>

            <li>W terminie i sposobie wskazanym w §2 Komisja dokonuje wyboru Laureata. Komisja bierze pod uwagę przede wszystkim zgodność Pracy Konkursowej z tematem Konkursu, jakoś i technikę wykonania, pomysłowość, poczucie humoru oraz nawiązanie do marki Diablo Chairs. </li>

            <li>Organizator przewiduje możliwość przyznania wyróżnień .</li>
          </ol>

          <p>§6. Nagrody</p>

          <ol>

            <li>Organizator przewiduje następujące nagrody:
              <ol type="a">
                <li>dla zwycięzcy: gadżety o wartości co najmniej 200 zł z oferty dostępnej na stronie  Organizatora: https://diablochairs.com/akcesoria.</li>
              </ol></li>

            <li>Do wartości nagrody zostanie dodana dodatkowa nagroda pieniężna w wysokości odpowiadającej zryczałtowanemu podatkowi dochodowemu od osób ﬁzycznych z tytułu wygranej w Konkursie, w kwocie stanowiącej 11,11% wartości Nagrody. Laureat zgadza się, że kwota dodatkowej nagrody pieniężnej nie podlega wypłacie na jego rzecz, lecz przeznaczona jest na zapłatę podatku należnego z tytułu wygranej Nagrody w Konkursie.</li>

            <li>Warunkiem wydania Nagrody jest przesłanie Organizatorowi przez Uczestnika z adresu poczty elektronicznej użytego przy  zgłoszeniu Pracy Konkursowej, danych osobowych niezbędny do wypełnienia obowiązku podatkowego o jakim mowa w ust 2. Organizator, jako płatnik zryczałtowanego podatku dochodowego od osób ﬁzycznych, przed wydaniem laureatowi Nagrody obliczy, pobierze i odprowadzi do właściwego Urzędu Skarbowego zryczałtowany podatek dochodowy należny z tytułu wygranej.</li>

            <li>Nagrody zostaną przesłane po przesłaniu Organizatorowi z adresu na jaki nagroda ma być dostarczona. Adres musi znajdować się w Polsce.  </li>

            <li>Przekazanie nagrody nastąpi za pośrednictwem Poczty Polskiej lub wybranego przez Organizatora Kuriera; wydanie nagród Poczcie Polskiej lub Kurierowi nastąpi w terminie 7 dni od otrzymania danych o jakich mowa w ust 4</li>

            <li>Organizatorowi przysługuje prawo weryﬁkacji danych osobowych Laureata w momencie wydania Nagrody, w celu identyﬁkacji osoby nagrodzonej, a także potwierdzenia jej pełnoletności, a w przypadku osoby niepełnoletniej/ograniczonej w czynnościach prawnych– prawo do weryfikacji zgody na udział w konkursie opiekuna prawnego Uczestnika. Przed wydaniem Nagrody Uczestnik może zostać zobligowany do okazania stosownego dokumentu.</li>
          </ol>


          <p>§7. Prawa własności intelektualnej do Prac Konkursowych</p>

          <ol>
            <li>Biorąc udział w Konkursie, Uczestnik jednocześnie oświadcza, że jest twórcą Pracy Konkursowej, w rozumieniu ustawy z dnia 4 lutego 1994 r. o prawie autorskim i prawach pokrewnych oraz, że przysługują mu pełne autorskie prawa majątkowe do Pracy Konkursowej wraz z ewentualnymi zezwoleniami na rozpowszechnianie utrwalonych na zgłaszanej Pracy Konkursowej wizerunków Uczestnika oraz osób trzecich.</li>
            <li>W przypadku gdy Praca Konkursowa polega na utrwaleniu za pomocą fotografii (lub innej techniki rejestrującej obraz i/lub dźwięk) utworu Uczestnika (np. rzeźby, wypowiedzi) za Prace Konkursową, której dotyczy przeniesienie praw  o jakich mowa poniżej,  uważa się to utrwalenie.</li>
            <li>Organizator z chwilą wydania Nagrody nabywa pełne, w tym autorskie prawa majątkowe i prawa zależne do Prac Konkursowych, stanowiących utwór, na wszystkich polach eksploatacji znanych w chwili wydania Nagrody, w tym wymienionych w art. 50 Ustawy, bez jakichkolwiek ograniczeń terytorialnych czy też czasowych, co w szczególności obejmuje następujące pola eksploatacji:
              <ol type="a">
                <li>w zakresie utrwalania i zwielokrotniania utworu oraz jego opracowań – wytwarzanie dowolną techniką, w tym techniką drukarską, reprograﬁczną, zapisu magnetycznego oraz techniką cyfrową,</li>
                <li>w zakresie obrotu oryginałami utworu oraz jego opracowaniami albo egzemplarzami, na których utwór utrwalono – wprowadzania do obrotu, użyczania lub najmu oryginału albo egzemplarzy,</li>
                <li>wykorzystanie we wszelkich formach komunikacji, promocji lub reklamy, w szczególności w charakterze materiału reklamowego lub promocyjnego, w charakterze elementu materiałów reklamowych lub promocyjnych takich jak ogłoszenia prasowe, reklama poprzez portale społecznościowe (np. Facebook, Instagram), reklamy zewnętrzne, ﬁlmy reklamowe, rozpowszechnianie w sieci Internet,</li>
              </ol></li>

            <li>Uczestnik  upoważnia Organizatora do realizowania przysługujących mu osobistych autorskich praw w zakresie anonimowego rozpowszechniania nagrodzonej Pracy Konkursowej, w zakresie decydowania o jej pierwszym publicznym udostępnieniu oraz sprawowania nadzoru nad sposobem korzystania z Pracy Konkursowej,</li>

            <li>Organizator nabywa prawo  do wykonywania praw zależnych do Utworów oraz do zezwalania innym osobom na wykonywanie praw zależnych do Utworów.</li>

            <li>Uczestnik wyraża niniejszym bezterminową i nieodwołalną zgodę na dokonywanie naruszenia integralności treści i formy Utworów oraz swobodne ich wykorzystanie przez Organizatora.</li>

            <li>W razie konieczności, laureat na żądanie Organizatora podpisze stosowne oświadczenia lub umowy potwierdzające
              <ol type="I">
                <li>przeniesienie praw autorskich do nagrodzonych Prac Konkursowych lub</li>
                <li>możliwość korzystania z utrwalonego na Pracy Konkursowej wizerunku osób ﬁzycznych.</li>

              </ol>
            </li>

            <li>Uczestnik ponosi pełną odpowiedzialność względem Organizatora w przypadku zgłoszenia przez osoby trzecie roszczeń lub pociągnięcia Organizatora do odpowiedzialności w związku z wykorzystaniem przez Organizatora Prac Konkursowych, do których prawa nabył na mocy Regulaminu.</li>

            <li>Z uwagi na to, że rezultatem udziału w Konkursie może być przyznanie Uczestnikowi Nagrody na zasadach opisanych w Regulaminie, z tytułu przeniesienia praw nie przysługuje dodatkowe wynagrodzenie.</li>
          </ol>

          <p>§8. Przetwarzanie danych osobowych</p>

          <ol>
            <li>Zgłaszając udział w Konkursie Uczestnik, będący osobą ﬁzyczną, wyraża zgodę na przetwarzanie podanych przez niego danych osobowych przez Organizatora jako administratora,  na zasadach opisanych w  Rozporządzeniu Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) dalej „RODO”.  Za dane Uczestnika rozumie się także dane jego przedstawiciela ustawowego.</li>

            <li>Podanie danych osobowych w postaci konta e-maila  z jakiego praca jest wysyłana jest dobrowolne, ale niezbędne do uczestnictwa w Konkursie. Podanie danych niezbędnych do uiszczenia podatku oraz wysyłki Nagrody, a także ustalenia pełnoletności oraz dane przedstawiciela ustawowego   jest dobrowolnemu ale niezbędne do realizacji tych czynności.</li>

            <li>Organizator, jako administrator danych osobowych, informuje, że jest administratorem danych osobowych Uczestnika w rozumieniu RODO, a dane osobowe Uczestnika będą przetwarzane:

              <ol type="I">

                <li>w celu związanym z organizacją i realizacją Konkursu, w tym  przeniesienia  praw autorskich na podstawie art. 6 ust. 1 lit. a), b) i c) RODO;</li>

                <li>w celu wykonywania obowiązków podatkowych, na podstawie art. 6 ust. 1 lit. c) i f) RODO;</li>

              </ol>
            </li>

            <li>Dane osobowe Uczestników Konkursów będą przetwarzane przez Organizatora przez czas niezbędny dla realizacji celów, w związku z którymi zostały pozyskane t.j. dane Uczestników do czasu zakończenia i rozstrzygnięcia Konkursu oraz przez okres wygaśnięcia ewentualnych roszczeń Uczestników, związanych z Konkursem, dane Laureatów do czasu wygaśnięcia ewentualnych ich roszczeń oraz dla celów sprawozdawczości wymaganej przepisami prawa;  rozpoznanie reklamacji przez okres wygaśnięcia ewentualnych roszczeń osoby zgłaszającej reklamację; realizacja uprawnień prawno-autorskich wskazanych przez okres posiadania takich uprawnień, realizacji obowiązków podatkowych – przez okres przedawnienia tych obowiązków.</li>

            <li>Uczestnikom Konkursu przysługuje prawo żądania dostępu do ich danych osobowych, sprostowania, usunięcia lub ograniczenia przetwarzania tych danych oraz prawo do wniesienia sprzeciwu wobec przetwarzania, a także prawo do przenoszenia danych, w zakresie przewidzianym prawem.</li>

            <li>Uczestnikom Konkursu przysługuje prawo do cofnięcia wyrażonej zgody na przetwarzanie ich danych osobowych w dowolnym momencie, co jednak nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie wyrażonej zgody przed jej cofnięciem – w tym celu Uczestnicy Konkursu wysłać odpowiednią informację na adres: Organizatora. W wyniku cofnięcia zgody Uczestnik jest pozbawiony prawa uczestnictwa w Konkursie.</li>

            <li>Uczestnikom Konkursu przysługuje prawo do złożenia skargi do organu nadzorczego – Prezesa Urzędu Ochrony Danych Osobowych.</li>

            <li>Decyzje dotyczące przebiegu Konkursu nie będą podejmowane w sposób zautomatyzowany i nie będą podlegać proﬁlowaniu.</li>

            <li>Odbiorcami danych osobowych mogą być, oprócz Prowadzącego: pracownicy lub współpracownicy Organizatora, jak również podmioty świadczące na rzecz Organizatora lub Współorganizatora Konkursu, usługi pocztowe, kurierskie, bankowe, doradcze, ﬁnansowe, rachunkowe, podatkowe, audytorskie, prawne lub zajmujące się dostarczaniem usług hostingowych lub usług związanych z systemami i oprogramowaniem IT.</li>
          </ol>

          <p>§9. Postępowanie reklamacyjne</p>

          <ol>
            <li>Prawo do złożenia reklamacji przysługuje Uczestnikom. Podstawę reklamacji stanowi naruszenie Regulaminu lub obowiązujących przepisów prawa.</li>

            <li>Zgłoszenie reklamacyjne winno być złożone pocztą elektroniczną na adres Organizatora plebiscyt@diablochairs.com lub dostarczone na piśmie na adres Organizatora Domator24.com - Paweł Nowak, ul. Dekoracyjna 8, 65-155 Zielona Góra, NIP 9730551869 , REGON 978094558 i powinno zawierać: dokładne dane zgłaszającego reklamację, jego adres, datę i miejsce zdarzenia, adres uczestnika, rodzaj konkursu, treść żądania.</li>

            <li>Reklamacje będą rozpatrywane przez Organizatora.</li>

            <li>Uczestnik wnoszący reklamację zostanie powiadomiony o rozstrzygnięciu za pomocą listu poleconego lub pocztą elektroniczną na adres wskazany przez Uczestnika, nadanego najpóźniej w terminie 14 dni od daty otrzymania reklamacji przez Organizatora.</li>

          </ol>

          <em>
            <p>OŚWIADCZENIE (wypełnić, gdy uczestnikiem Konkursu jest osoba niepełnoletnia lub ograniczona w zakresie czynności prawnych )</p>

              <p>Niniejszym wyrażam zgodę na udział ………………………………………, którego jestem rodzicem/prawnym opiekunem, na udział w Konkursie „Fotel Roku 2021”, którego regulamin dostępny jest pod adresem https://plebiscyt.diablochairs.com/, którego Organizatorem jest Domator24.com - Paweł Nowak, ul. Dekoracyjna 8, 65-155 Zielona Góra, na zasadach opisanych w regulaminie.</p>

              <p>Oświadczam, że akceptuję regulamin Konkursu, a ponadto:</p>
              <ol>
                <li>oświadczam, że moje dziecko/podopieczny  jest jedynym autorem pracy konkursowej i wyrażam zgodę na przeniesienie praw autorskich na zasadach opisanych Regulaminie w przypadku wyłonienia go jako laureata Konkursu,</li>
                <li>praca zgłoszona na Konkurs nie narusza prawa, w szczególności dóbr osobistych osób trzecich,</li>
                <li>wyrażam zgodę na wszelkie utrwalenie i zwielokrotnienie i inne komercyjne rozpowszechnianie i publiczne udostępnienie zgłoszonej do Konkursu pracy,</li>
                <li>wyrażam zgodę na przetwarzanie i udostępnianie danych osobowych dziecka/podopiecznego zgodnie z przepisami RODO w sposób opisany w Regulaminie,</li>
                <li>wyrażam zgodę na przetwarzanie i udostępnianie moich danych osobowych zgodnie z przepisami RODO w sposób opisany w Regulaminie (stosuje się odpowiednio postanowienia dotyczące Uczestników Konkursów).</li>
              </ol>
          </em>

          <button @click="$router.go(-1)" class="main-btn white">WRÓĆ</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Regulation',
}
</script>

<style scoped>

</style>
