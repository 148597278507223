import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";

const vuexPersist = new VuexPersistence({
    key: 'plebiscyt_2023',
    storage: window.localStorage
})

export default createStore({
    state: {
        // Categories
        creator_id: 0,
        chair_id: 0,
        advert_id: 0,
        gadget_id: 0,

        already_vote: false,
        one_category: false
    },
    mutations: {
        // Categories
        setCreatorId(state, payload) { state.creator_id = payload.creator_id },
        setChairId(state, payload) { state.chair_id = payload.chair_id },
        setAdvertId(state, payload) { state.advert_id = payload.advert_id },
        setGadgetId(state, payload) { state.gadget_id = payload.gadget_id },

        setAlreadyVote(state, payload) { state.already_vote = payload.already_vote },
        setOneCategory(state, payload) { state.one_category = payload.one_category },
    },
    plugins: [vuexPersist.plugin]
});