<template>
  <div class="category-section" id="chair-category">

    <div class="category-section-top mt-4 mb-4">
      <img src="/img/icons/category-chair.svg" alt="logo" class="category-img"/>
      <div class="category-section-top-content">
        <p class="subhead"><span>II</span> Kategoria</p>
        <h2><span>Fotel</span> roku</h2>
      </div>
    </div>

    <div class="category-section-content">
      <div class="row">

        <div class="col-lg-2 col-md-3 col-sm-4 col-6" v-for="chair in chairs" :key="chair">
          <div :class="{ active: chair.id === chair_id, 'category-item-block': true }" @click="setChair(chair.id)">

            <div class="top-category-block">
              <img :src="'/img/chairs/' + chair.image" alt="logo" class="category-img"/>
            </div>

            <button class="main-btn bottom-category-block">
              <span class="round"></span>
              <span class="title">{{ chair.name }}</span>
            </button>
          </div>

          <a :href="chair.url" target="_blank" class="link">Dowiedz się więcej</a>
        </div>

      </div>
    </div>

    <div class="category-button-content">
      <button class="category-button" @click="scrollTo('advert-category')">
        <span>Następna </span> kategoria
        <img src="/img/icons/top.svg" alt="top" class="top-img"/>
      </button>
    </div>

  </div>
</template>

<script>
import {CHAIRS} from "@/utils/data";

export default {
  name: 'Chair',
  data() {
    return {
      chairs: CHAIRS
    }
  },
  methods: {
    setChair(id) {
      this.chair_id = id;
      this.one_category = true;
    },
    scrollTo(element) {
      const el = document.getElementById(element);
      el.scrollIntoView({behavior: "smooth"});
    }
  },
  computed: {
    chair_id: {
      get() {
        return this.$store.state.chair_id;
      },
      set(value) {
        this.$store.commit('setChairId', {chair_id: value})
      }
    },
    one_category: {
      get() {
        return this.$store.state.one_category;
      },
      set(value) {
        this.$store.commit('setOneCategory', {one_category: value})
      }
    },
  }
}
</script>