<template>
  <div class="category-section" id="gadget-category">

    <div class="category-section-top mt-4 mb-4">
      <img src="/img/icons/category-gadget.svg" alt="logo" class="category-img"/>
      <div class="category-section-top-content">
        <p class="subhead"><span>IV</span> Kategoria</p>
        <h2><span>Gadżet</span> roku</h2>
      </div>
    </div>

    <div class="category-section-content">
      <div class="row">

        <div class="col-lg-2 col-md-3 col-sm-4 col-6" v-for="gadget in gadgets" :key="gadget">
          <div :class="{ active: gadget.id === gadget_id, 'category-item-block': true }" @click="setGadget(gadget.id)">

            <div class="top-category-block">
              <img :src="'/img/gadgets/' + gadget.image" alt="logo" class="category-img"/>
              <div class="name-align-center">
                <p class="name">{{ gadget.name }}</p>
              </div>
            </div>

            <button class="main-btn bottom-category-block">
              <span class="round"></span>
              <span class="title">Wybieram</span>
            </button>
          </div>

          <a :href="gadget.url" target="_blank" class="link">Dowiedz się więcej</a>
        </div>
      </div>
    </div>

    <div class="category-button-content">
      <button class="category-button" @click="scrollTo('vote-category')">
        <span>Następna </span> kategoria
        <img src="/img/icons/top.svg" alt="top" class="top-img"/>
      </button>
    </div>

  </div>
</template>

<script>
import {GADGETS} from "@/utils/data";

export default {
  name: 'Gadget',
  data() {
    return {
      gadgets: GADGETS
    }
  },
  methods: {
    setGadget(id) {
      this.gadget_id = id;
      this.one_category = true;
    },
    scrollTo(element) {
      const el = document.getElementById(element);
      el.scrollIntoView({behavior: "smooth"});
    }
  },
  computed: {
    gadget_id: {
      get() {
        return this.$store.state.gadget_id;
      },
      set(value) {
        this.$store.commit('setGadgetId', {gadget_id: value})
      }
    },
    one_category: {
      get() {
        return this.$store.state.one_category;
      },
      set(value) {
        this.$store.commit('setOneCategory', {one_category: value})
      }
    },
  }
}
</script>