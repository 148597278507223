<template>
  <div class="cookie-banner" v-if="cookieBanner">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <p>My lubimy ciastka, a Ty? Korzystając z naszej strony zgadzasz się chrupać je razem z nami. Więcej informacji o plikach cookies znajdziesz w <a href="https://diablochairs.com/regulamin-plebiscyt" target="_blank">regulaminie</a>.</p>
          <button class="close-btn" type="button" @click="toggleCookie()">
            <img src="/img/icons/close.svg" alt="logo" class="youtube-logo"/>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Cookie',
  data() {
    return {
      cookieBanner: true,
    }
  },
  created() {
    if(this.$cookie.getCookie('AcceptCookie')) {
      this.cookieBanner = false;
    }
  },
  methods: {
    toggleCookie() {
      this.$cookie.setCookie('AcceptCookie', 'Cookie');
      this.cookieBanner = !this.cookieBanner;
    }
  }
}
</script>