import Home from "@/components/Home";
import NotFound from "@/components/error/NotFound";
import Regulation from "@/components/pages/Regulation";

const routes = [
    { path: '/', name: 'Home', component: Home, meta: { title: '' } },
    { path: '/regulamin', name: 'Regulation', component: Regulation, meta: { title: 'Regulamin' } },
    { path: "/:pathMatch(.*)*", name: 'NotFound', component: NotFound, meta: { title: 'Error 404' } }
];

export default routes;