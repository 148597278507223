import { createRouter, createWebHistory } from 'vue-router'
import routes from './routes'

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to) => {
    if(to.meta.title === '') {
        document.title = 'Diablo Plebiscyt';
    } else {
        document.title = to.meta.title + ' | Diablo Plebiscyt';
    }
});

export default router;