<template>
  <div class="notfound">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="center-notfound">
            <div class="notfound-box">
              <img src="/img/icons/plebiscyt-full-logo.svg" alt="logo" class="logo"/>
              <p class="text">Niestety, nie znaleziono podanej strony, wciśnij przycisk poniżej, aby wrócić do strony głównej.</p>
              <router-link :to="{ name: 'Home' }" class="main-btn white">WRÓĆ</router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'NotFound',
  }
</script>

<style scoped>

</style>
