<template>
  <footer class="footer">

    <div class="container-fluid">
      <div class="row">
        <div class="col-12">
          <div class="height-align-center logo-center">
            <img src="/img/icons/logo-diablo.svg" alt="logo" class="logo"/>
          </div>
        </div>

        <div class="col-12">
          <div class="footer-links">
            <a href="https://diablochairs.com" target="_blank">DiabloChairs</a>
            <a href="https://diablochairs.com/regulamin-plebiscyt" target="_blank">Regulamin</a>
            <a href="https://diablochairs.com/polityka-prywatnosci" target="_blank">Polityka prywatności</a>
          </div>
        </div>
      </div>
    </div>

  </footer>
</template>

<script>
export default {
  name: 'Footer'
}
</script>